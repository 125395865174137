import React from "react";
import { createRoot } from "react-dom/client";
import App from "./App";

import {
  PublicClientApplication,
  EventType,
  InteractionType,
} from "@azure/msal-browser";
import { loginRequest, msalConfig } from "./authConfig";
import { MsalAuthenticationTemplate, MsalProvider } from "@azure/msal-react";
import {
  createBrowserRouter,
  Navigate,
  RouterProvider,
} from "react-router-dom";
import { getAllShopifyCustomers } from "./api/ShopifyCustomer";
import ModifyCustomerTable from "./ModifyCutsomerTable";
import DisableCustomerTable from "./DisableCustomerTable";
import ManageDiscountCodes from "./ManageDiscountCodes";
import CreateCustomer from "./CreateCustomer";
import {
  getAllShopifyPriceRules,
  getShopifyPriceRuleByID,
} from "./api/ShopifyPriceRules";
import { getAllShopifyOrders } from "./api/ShopifyOrders";
import DiscountCodeDetails from "./DiscountCodeDetails";
import DiscountCodeAddCustomers from "./DiscountCodeAddCustomers";
import CreateCustomerSingle from "./CreateCustomerSingle";
import CreateCustomerMultiple from "./CreateCustomerMultiple";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import CreateDiscountCode from "./CreateDiscountCode";


export const msalInstance = new PublicClientApplication(msalConfig);

// Default to using the first account if no account is active on page load
if (
  !msalInstance.getActiveAccount() &&
  msalInstance.getAllAccounts().length > 0
) {
  // Account selection logic is app dependent. Adjust as needed for different use cases.
  msalInstance.setActiveAccount(msalInstance.getAllAccounts()[0]);
}

msalInstance.addEventCallback((event) => {
  if (
    (event.eventType === EventType.LOGIN_SUCCESS ||
      event.eventType === EventType.ACQUIRE_TOKEN_SUCCESS ||
      event.eventType === EventType.SSO_SILENT_SUCCESS) &&
    event.payload.account
  ) {
    msalInstance.setActiveAccount(event.payload.account);
  }
});

const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
    children: [
      {
        path: "/",
        element: <Navigate to="/modify-customers" />,
      },
      {
        path: "/modify-customers",
        element: <ModifyCustomerTable />,
        loader: () => {
          return getAllShopifyCustomers();
        },
      },
      {
        path: "/create-customers",
        element: <CreateCustomer />,
      },
      {
        path: "/create-customers/single",
        element: <CreateCustomerSingle />,
      },
      {
        path: "/create-customers/multiple",
        element: <CreateCustomerMultiple />,
      },
      {
        path: "/disable-customers",
        element: <DisableCustomerTable />,
        loader: () => {
          return getAllShopifyCustomers();
        },
      },
      {
        path: "/discount-codes",
        element: <ManageDiscountCodes />,
        loader: async () => {
          return getAllShopifyPriceRules();
        },
      },
      {
        path: "/create-discount-code",
        element: <CreateDiscountCode />
      },
      {
        path: "/discount-codes/:discountCodeID",
        element: <DiscountCodeDetails />,
        loader: async (req) => {
          let allCustomers = getAllShopifyCustomers();
          let discountCode = getShopifyPriceRuleByID(req.params.discountCodeID);
          let allOrders = getAllShopifyOrders();
          return await Promise.all([allCustomers, discountCode, allOrders]);
        },
      },
      {
        path: "/discount-codes/:discountCodeID/add",
        element: <DiscountCodeAddCustomers />,
        loader: async (req) => {
          let allCustomers = getAllShopifyCustomers();
          let discountCode = getShopifyPriceRuleByID(req.params.discountCodeID);
          return await Promise.all([allCustomers, discountCode]);
        },
      },
    ],
  },
]);

const root = createRoot(document.getElementById("root"));
root.render(
  <MsalProvider instance={msalInstance}>
    <MsalAuthenticationTemplate
      interactionType={InteractionType.Redirect}
      authenticationRequest={loginRequest}
    >
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <RouterProvider router={router} />
      </LocalizationProvider>
    </MsalAuthenticationTemplate>
  </MsalProvider>
);
