import {
  Alert,
  Button,
  FormControlLabel,
  FormGroup,
  Grid,
  Paper,
  Snackbar,
  Switch,
  Typography,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { useState } from "react";
import { Await, useLoaderData } from "react-router";
import { modifyShopifyPriceRule } from "./api/ShopifyPriceRules";
import { useNavigate } from "react-router-dom";

export default function DiscountCodeAddCustomers() {
  const [allCustomers, priceRule] = useLoaderData();

  // Filter Customers by Employer Metafield
  const validEmployerMetafieldValues = [
    "ccofs",
    "envision",
    "aofs",
    "flagship",
    "rockcliff",
    "uoms",
  ];

  let filteredCustomers = allCustomers.filter((customer) => {
    if (customer.metafields !== undefined) {
      return customer.metafields.find(
        (metafield) =>
          metafield.key === "employer" &&
          validEmployerMetafieldValues.includes(metafield.value)
      );
    } else {
      return false;
    }
  });

  const navigate = useNavigate();

  const [selectedCustomers, setSelectedCustomers] = useState([]);
  const [sendEmployeeNotification, setSendEmployeeNotification] =
    useState(true);
  const [successSnackbar, setSuccessSnackbar] = useState(false);
  const [failureSnackbar, setFailureSnackbar] = useState(false);
  const [unassignedCustomers, ] = useState(
    filteredCustomers.filter((customer) => {
      if (Object.hasOwn(priceRule, "customers")) {
        return !priceRule.customers.includes(
          "gid://shopify/CustomerSegmentMember/" + customer.id
        );
      }

      return !priceRule.customerSelection.customers.includes(customer.id);
    })
  );

  const discountCustomerColumns = [
    { field: "firstName", headerName: "First Name", width: 100 },
    { field: "lastName", headerName: "Last Name", width: 100 },
    { field: "email", headerName: "Email", width: 300 },
    {
      field: "Employer",
      headerName: "Employer",
      width: 100,
      editable: false,
      valueGetter: (params) => {
        let matched_meta = params.row.metafields.find(
          (meta) => meta.key === "employer"
        );
        return matched_meta ? matched_meta.value : null;
      },
    },
    {
      field: "Job Type",
      headerName: "Job Type",
      width: 100,
      editable: false,
      valueGetter: (params) => {
        let matched_meta = params.row.metafields.find(
          (meta) => meta.key === "job_type"
        );
        return matched_meta ? matched_meta.value : null;
      },
    },
    {
      field: "Work Location",
      headerName: "Work Location",
      width: 150,
      editable: false,
      valueGetter: (params) => {
        let matched_meta = params.row.metafields.find(
          (meta) => meta.key === "work_location"
        );
        return matched_meta ? matched_meta.value : null;
      },
    },
  ];

  async function handleAddCustBtn() {
    let pricerule_data = {};

    if (Object.hasOwn(priceRule, "customers")) {
      pricerule_data = {
        id: priceRule.id,
        priceRule: {
          oncePerCustomer: true,
        },
        addCustomers: selectedCustomers.map((cust) => cust.id),
      };
    } else {
      pricerule_data = {
        id: priceRule.id,
        priceRule: {
          customerSelection: {
            customerIdsToAdd: selectedCustomers.map(
              (cust) => "gid://shopify/Customer/" + cust.id
            ),
          },
          oncePerCustomer: true,
        },
      };
    }

    let updatedPriceRule = await modifyShopifyPriceRule(
      pricerule_data,
      sendEmployeeNotification
    );

    if (updatedPriceRule != null) {
      setSuccessSnackbar(true);
      navigate("/discount-codes/" + priceRule.id);
    } else {
      setFailureSnackbar(true);
    }
  }

  function handleRowSelect(ids) {
    const selectedIDs = new Set(ids);
    const selectedRowData = unassignedCustomers.filter((row) =>
      selectedIDs.has(row.id)
    );
    setSelectedCustomers(selectedRowData);
  }

  function notificationToggleHandler() {
    setSendEmployeeNotification(!sendEmployeeNotification);
  }

  function handleSuccessSnackClose(event, reason) {
    if (reason === "clickaway") {
      return;
    }

    setSuccessSnackbar(false);
  }

  function handleFailureSnackClose(event, reason) {
    if (reason === "clickaway") {
      return;
    }

    setFailureSnackbar(false);
  }

  return (
    <Await
      resolve={priceRule}
      // TODO - Add errorElement Here
    >
      <Grid container spacing={3}>
        <Grid item xs={12} md={12} lg={12}>
          <Paper
            sx={{
              p: 2,
              display: "flex",
              flexDirection: "column",
              height: "auto",
            }}
          >
            <Typography variant="h4">
              Adding Customers To: {priceRule.title}
            </Typography>
            <br></br>
            <Typography variant="h6">Unassigned Customers</Typography>
            <br></br>
            <Typography variant="h8">
              Note: Try to select no more than about 10 customers per
              submission.
            </Typography>
            <br></br>
            <div style={{ height: 900, width: "100%" }}>
              <DataGrid
                rows={unassignedCustomers}
                columns={discountCustomerColumns}
                pageSize={15}
                rowsPerPageOptions={[15]}
                checkboxSelection
                onSelectionModelChange={(ids) => handleRowSelect(ids)}
              />
            </div>
            <FormGroup>
              <FormControlLabel
                control={
                  <Switch defaultChecked onClick={notificationToggleHandler} />
                }
                label="Send Notification Email To Employee"
              />
              <Button variant="contained" onClick={handleAddCustBtn}>
                Add Selected Customer To Discount Code
              </Button>
            </FormGroup>
          </Paper>
        </Grid>
        <Snackbar
          open={successSnackbar}
          autoHideDuration={6000}
          onClose={() => handleSuccessSnackClose()}
        >
          <Alert
            onClose={() => handleSuccessSnackClose()}
            severity="success"
            sx={{ width: "100%" }}
          >
            Customers assigned to discount code succesfully!
          </Alert>
        </Snackbar>
        <Snackbar
          open={failureSnackbar}
          autoHideDuration={6000}
          onClose={() => handleFailureSnackClose()}
        >
          <Alert
            onClose={() => handleFailureSnackClose()}
            severity="error"
            sx={{ width: "100%" }}
          >
            Error unable to assign customers to discount code, please try again
            or contact Knighten Uniforms for support.
          </Alert>
        </Snackbar>
      </Grid>
    </Await>
  );
}
