import { apiRequest } from "../authConfig";
import { msalInstance } from "../index";

export async function getBearerToken() {
  const account = msalInstance.getActiveAccount();
  if (!account) {
    throw Error(
      "No active account! Verify a user has been signed in and setActiveAccount has been called."
    );
  }

  const response = await msalInstance.acquireTokenSilent({
    ...apiRequest,
    account: account,
  });

  return response.accessToken;
}

export async function getShopifyCustomerByID(customerId) {
  const token = await getBearerToken();

  const headers = new Headers();
  const bearer = `Bearer ${token}`;

  headers.append("Authorization", bearer);

  const options = {
    method: "GET",
    headers: headers,
  };

  return fetch(
    `https://ku-ordering-api.azurewebsites.net/api/shopify_customer/${customerId}?metafields=true&code=7ZrjOHw0Nhk2JvrQEE6OkSL7qHSPM4rc9mGSI4Hn1xIYAzFuvAbEsw==`,
    options
  )
    .then((response) => response.json())
    .catch((error) => console.log(error));
}

export async function getAllShopifyCustomers() {
  const token = await getBearerToken();

  const headers = new Headers();
  const bearer = `Bearer ${token}`;

  headers.append("Authorization", bearer);

  const options = {
    method: "GET",
    headers: headers,
  };

  return fetch(
    "https://ku-ordering-api.azurewebsites.net/api/shopify_customer?code=7ZrjOHw0Nhk2JvrQEE6OkSL7qHSPM4rc9mGSI4Hn1xIYAzFuvAbEsw==",
    options
  )
    .then((response) => response.json())
    .catch((error) => console.log(error));
}

export async function createShopifyCustomer(customer_data) {
  const token = await getBearerToken();

  const headers = new Headers();
  const bearer = `Bearer ${token}`;

  headers.append("Authorization", bearer);

  const options = {
    method: "POST",
    headers: headers,
    body: JSON.stringify(customer_data),
  };

  return fetch(
    "https://ku-ordering-api.azurewebsites.net/api/shopify_customer?code=7ZrjOHw0Nhk2JvrQEE6OkSL7qHSPM4rc9mGSI4Hn1xIYAzFuvAbEsw==",
    options
  )
    .then((response) => response.json())
    .catch((error) => console.log(error));
}

export async function modifyShopifyCustomer(customer_data) {
  const token = await getBearerToken();

  const headers = new Headers();
  const bearer = `Bearer ${token}`;

  headers.append("Authorization", bearer);

  const options = {
    method: "PUT",
    headers: headers,
    body: JSON.stringify(customer_data),
  };

  return fetch(
    "https://ku-ordering-api.azurewebsites.net/api/shopify_customer?code=7ZrjOHw0Nhk2JvrQEE6OkSL7qHSPM4rc9mGSI4Hn1xIYAzFuvAbEsw==",
    options
  )
    .then((response) => response.json())
    .catch((error) => console.log(error));
}
