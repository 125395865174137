import {
  Alert,
  Button,
  Dialog,
  DialogTitle,
  Grid,
  Paper,
  Snackbar,
  Typography,
} from "@mui/material";
import { DataGrid, GridActionsCellItem } from "@mui/x-data-grid";
import { useState } from "react";
import DisabledByDefaultIcon from "@mui/icons-material/DisabledByDefault";
import { Await, useLoaderData } from "react-router";
import { modifyShopifyCustomer } from "./api/ShopifyCustomer";

export default function DisableCustomerTable() {
  const customerLoader = useLoaderData();

  // Filter Customers by Employer Metafield
  const validEmployerMetafieldValues = [
    "ccofs",
    "envision",
    "aofs",
    "flagship",
    "rockcliff",
    "uoms",
  ];
  let filteredCustomers = customerLoader.filter((customer) => {
    if (customer.metafields !== undefined) {
      return customer.metafields.find(
        (metafield) =>
          metafield.key === "employer" &&
          validEmployerMetafieldValues.includes(metafield.value)
      );
    } else {
      return false;
    }
  });

  const [customers, setCustomers] = useState(filteredCustomers);
  const [successSnackbar, setSuccessSnackbar] = useState(false);
  const [failureSnackbar, setFailureSnackbar] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [activeCustomer, setActiveCustomer] = useState({
    first_name: "place",
    last_name: "holder",
  });

  async function handleDisableClick(id) {
    let customerIdx = customers.findIndex((customer) => {
      return customer.id === id;
    });
    let customerCopy = { ...customers[customerIdx] };
    setActiveCustomer(customerCopy);
    setOpenDialog(true);
  }

  const handleDelete = async (value) => {
    let customerPayload = {
      id: customers.find((customer) => customer.id === activeCustomer.id).id,
      tags: "disabled",
    };

    let updatedCustomer = await modifyShopifyCustomer(customerPayload);

    if (updatedCustomer != null) {
      let customerCopy = [...customers];
      customerCopy[
        customers.findIndex((customer) => customer.id === activeCustomer.id)
      ] = updatedCustomer;
      setCustomers(customerCopy);
      setSuccessSnackbar(true);
    } else {
      setFailureSnackbar(true);
    }

    setOpenDialog(false);
  };

  const handleClose = () => {
    setOpenDialog(false);
  };
  const columns = [
    { field: "firstName", headerName: "First Name", width: 150 },
    { field: "lastName", headerName: "Last Lame", width: 150 },
    { field: "email", headerName: "Email", width: 300 },
    {
      field: "Account Status",
      headerName: "Account Status",
      width: 200,
      editable: false,
      valueGetter: (params) => {
        let hasDisabledTag = params.row.tags.includes("disabled");
        return !hasDisabledTag ? "Enabled" : "Disabled";
      },
    },
    {
      field: "actions",
      type: "actions",
      headerName: "Disable",
      width: 100,
      cellClassName: "actions",
      getActions: (params) => {
        return [
          <GridActionsCellItem
            icon={<DisabledByDefaultIcon />}
            label="Disable"
            onClick={() => handleDisableClick(params.id)}
            disabled={customers
              .find((customer) => customer.id === params.id)
              .tags.includes("disabled")}
            color={
              customers
                .find((customer) => customer.id === params.id)
                .tags.includes("disabled")
                ? "disabled"
                : "warning"
            }
          />,
        ];
      },
    },
  ];

  function handleSuccessSnackClose(event, reason) {
    if (reason === "clickaway") {
      return;
    }

    setSuccessSnackbar(false);
  }

  function handleFailureSnackClose(event, reason) {
    if (reason === "clickaway") {
      return;
    }

    setFailureSnackbar(false);
  }

  return (
    <Await
      resolve={customers}
      // TODO - Add errorElement Here
    >
      <Grid container spacing={3}>
        <Grid item xs={12} md={12} lg={12}>
          <Paper
            sx={{
              p: 2,
              display: "flex",
              flexDirection: "column",
              height: "auto",
            }}
          >
            <Typography variant="h4">Disable Customers</Typography>
            <br></br>
            <Typography variant="h6">How To Use </Typography> Press the red x
            button on the customers row to disable the customers account. You
            can only disable an account, you cannot reenable an account. If an
            account is already disabled it will appear with a grey x instead of
            red. If you need an customer reenabled contact Knighten Uniforms
            (johnny@knightenuniforms.com)
            <br></br>
            <br></br>
            <div style={{ height: 700, width: "100%" }}>
              <DataGrid
                rows={customers}
                columns={columns}
                pageSize={10}
                rowsPerPageOptions={[10]}
              />
            </div>
          </Paper>
        </Grid>
        <Snackbar
          open={successSnackbar}
          autoHideDuration={6000}
          onClose={() => handleSuccessSnackClose()}
        >
          <Alert
            onClose={() => handleSuccessSnackClose()}
            severity="success"
            sx={{ width: "100%" }}
          >
            Customer disabled successfully!
          </Alert>
        </Snackbar>
        <Snackbar
          open={failureSnackbar}
          autoHideDuration={6000}
          onClose={() => handleFailureSnackClose()}
        >
          <Alert
            onClose={() => handleFailureSnackClose()}
            severity="error"
            sx={{ width: "100%" }}
          >
            Error disabling customer, please try again or conntact Knighten
            Uniforms for support.
          </Alert>
        </Snackbar>
        <Dialog open={openDialog} onClose={handleClose}>
          <DialogTitle>
            Are you sure you want to disable{" "}
            {activeCustomer.first_name + " " + activeCustomer.last_name}?
          </DialogTitle>
          <Button onClick={handleDelete} color="warning">
            Disable
          </Button>
          <Button onClick={handleClose}>Cancel</Button>
        </Dialog>
      </Grid>
    </Await>
  );
}
