import {
  Alert,
  Button,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  MenuItem,
  Paper,
  Radio,
  RadioGroup,
  Select,
  Snackbar,
  TextField,
  Typography,
} from "@mui/material";
import { useState } from "react";
import { createShopifyCustomer } from "./api/ShopifyCustomer";
import { DatePicker } from "@mui/x-date-pickers";

export default function CreateCustomerSingle() {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [brand, setBrand] = useState("ccofs");
  const [jobType, setJobType] = useState("clinical");
  const [hireDate, setHireDate] = useState("");
  const [workLocation, setWorkLocation] = useState("");
  const [isFirstNameValid, setIsFirstNameValid] = useState(true);
  const [isLastNameValid, setIsLastNameValid] = useState(true);
  const [isEmailValid, setIsEmailValid] = useState(true);
  const [isWorkLocationValid, setIsWorkLocationValid] = useState(true);
  const [isHireDateValid, setIsHireDateValid] = useState(false);
  const [failureSnackbar, setFailureSnackbar] = useState(false);
  const [successSnackbar, setSuccessSnackbar] = useState(false);

  // TODO - Make Into JSON File Config
  let brandWorkLocationMap = {
    ccofs: [
      "AOMS : Lexington",
      "AOMS : West Columbia",
      "Billingsley",
      "Blakeney",
      "CCOFS of Anderson",
      "Cherrydale",
      "Columbia",
      "Concord",
      "Denver",
      "Eastside",
      "Gastonia",
      "Greenwood",
      "Matthews",
      "Mooresville",
      "Rock Hill",
      "Simpsonville",
      "Southern Pines",
      "Spartanburg : East",
      "Spartanburg : West",
      "University",
      "Verdae",
    ],
    envision: ["Envision - Blakeney", "Envision - Issaqueena"],
    aofs: ["Bermuda Run / Advance Office", "Kernersville"],
    flagship: ["Corporate"],
    rockcliff: [
      "Asheville",
      "Hendersonville",
      "Sylva",
      "Vanderbilt",
      "Waynesville",
    ],
    uoms: ["OMS Upstate Easley", "OMS Upstate Seneca"],
  };

  async function handleCreateCustomer() {
    validateFirstName(firstName);
    validateLastName(lastName);
    validateEmail(email);
    validateWorkLocation(workLocation);
    validateHireDate(hireDate);

    if (
      isFirstNameValid &&
      isLastNameValid &&
      isEmailValid &&
      isWorkLocationValid &&
      isHireDateValid
    ) {
      const new_customer = {
        firstName: firstName,
        lastName: lastName,
        email: email,
        employer: brand,
        work_location: workLocation,
        job_type: jobType,
        hire_date: hireDate.toISOString().slice(0, 10),
        tags: "Company - Flagship",
      };

      const new_customer_returned = await createShopifyCustomer(new_customer);

      if (new_customer_returned) {
        setSuccessSnackbar(true);
        window.location.reload();
      } else {
        setFailureSnackbar(true);
      }
    }
  }

  let handleFirstNameChange = (event) => {
    validateFirstName(event.target.value);
    setFirstName(event.target.value);
  };

  function validateFirstName(name) {
    setIsFirstNameValid(name.length > 0);
  }

  let handleLastNameChange = (event) => {
    validateLastName(event.target.value);
    setLastName(event.target.value);
  };

  function validateLastName(name) {
    setIsLastNameValid(name.length > 0);
  }

  let handleEmailChange = (event) => {
    validateEmail(event.target.value);
    setEmail(event.target.value);
  };

  function validateEmail(email) {
    let valid = email
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
    setIsEmailValid(valid);
  }

  let handleWorkLocationChange = (event) => {
    validateWorkLocation(event.target.value);
    setWorkLocation(event.target.value);
  };

  function validateWorkLocation(workLocation) {
    setIsWorkLocationValid(workLocation !== "");
  }

  let handleHireDateChange = (newDate) => {
    validateHireDate(newDate);
    setHireDate(newDate);
  };

  let validateHireDate = (hireDate) => {
    setIsHireDateValid(hireDate !== "");
  };

  function handleFailureSnackClose(event, reason) {
    if (reason === "clickaway") {
      return;
    }

    setFailureSnackbar(false);
  }

  function handleSuccessSnackClose(event, reason) {
    if (reason === "clickaway") {
      return;
    }

    setSuccessSnackbar(false);
  }

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} md={12} lg={12}>
        <Paper
          sx={{
            p: 2,
            display: "flex",
            flexDirection: "column",
            height: "auto",
          }}
        >
          <Typography variant="h4">Create Customer</Typography>
          <br></br>
          <Typography variant="subtitle1">Name</Typography>
          <Grid container columns={2}>
            <Grid item>
              <FormControl sx={{ m: 1, width: 500 }}>
                <TextField
                  required
                  id="last_name"
                  label="Last Name"
                  variant="standard"
                  error={!isLastNameValid}
                  helperText={
                    isLastNameValid ? "" : "Last Name Cannot Be Empty"
                  }
                  onChange={handleLastNameChange}
                />
              </FormControl>
            </Grid>
            <Grid item>
              <FormControl sx={{ m: 1, width: 500 }}>
                <TextField
                  required
                  id="first_name"
                  label="First Name"
                  variant="standard"
                  error={!isFirstNameValid}
                  helperText={
                    isFirstNameValid ? "" : "First Name Cannot Be Empty"
                  }
                  onChange={handleFirstNameChange}
                />
              </FormControl>
            </Grid>
          </Grid>
          <br></br>
          <br></br>
          <Typography variant="subtitle1">Contact Info</Typography>
          <Grid container columns={2}>
            <Grid item>
              <FormControl sx={{ m: 1, width: 500 }}>
                <TextField
                  required
                  id="email"
                  label="Email"
                  variant="standard"
                  error={!isEmailValid}
                  helperText={isEmailValid ? "" : "Enter A Valid Email Address"}
                  onChange={handleEmailChange}
                />
              </FormControl>
            </Grid>
            <Grid item></Grid>
          </Grid>
          <br></br>
          <br></br>
          <Typography variant="subtitle1">Job Info</Typography>
          <Grid container columns={2}>
            <Grid item>
              <FormControl sx={{ m: 1, width: 500 }}>
                <FormLabel id="demo-radio-buttons-group-label">Type</FormLabel>
                <RadioGroup
                  row
                  defaultValue="clinical"
                  name="radio-buttons-group"
                  value={jobType}
                  onChange={(event) => setJobType(event.target.value)}
                >
                  <FormControlLabel
                    value="clinical"
                    control={<Radio />}
                    label="Clinical"
                  />
                  <FormControlLabel
                    value="admin"
                    control={<Radio />}
                    label="Admin"
                  />
                </RadioGroup>
              </FormControl>
            </Grid>
            <Grid item>
              <FormControl sx={{ m: 1, width: 500 }}>
                <DatePicker
                  label="Hire Date"
                  onChange={handleHireDateChange}
                  format="YYYY-MM-DD"
                />
              </FormControl>
            </Grid>
          </Grid>
          <Grid container columns={2}>
            <Grid item>
              <FormControl sx={{ m: 1, width: 500 }}>
                <FormLabel id="demo-radio-buttons-group-label">Brand</FormLabel>
                <Select
                  required
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={brand}
                  label="Brand"
                  onChange={(event) => setBrand(event.target.value)}
                >
                  <MenuItem value={"ccofs"}>CCOFS</MenuItem>
                  <MenuItem value={"rockcliff"}>Rockcliff</MenuItem>
                  <MenuItem value={"aofs"}>
                    Advanced Oral & Facial Surgery of the Triad
                  </MenuItem>
                  <MenuItem value={"flagship"}>Flagship</MenuItem>
                  <MenuItem value={"envision"}>Envision</MenuItem>
                  <MenuItem value={"uoms"}>
                    Upstate Oral & Maxillofacial Surgery
                  </MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item>
              <FormControl
                sx={{ m: 1, width: 500 }}
                error={!isWorkLocationValid}
              >
                <FormLabel id="demo-radio-buttons-group-label">
                  Work Location
                </FormLabel>
                <Select
                  required
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={workLocation}
                  label="Work Location"
                  onChange={handleWorkLocationChange}
                >
                  {brandWorkLocationMap[brand].map((location) => (
                    <MenuItem value={location} key={location}>
                      {location}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid>

          <br></br>
          <Button
            type="button"
            className="btn btn-primary"
            variant="contained"
            onClick={handleCreateCustomer}
          >
            Create Shopify Customer
          </Button>
        </Paper>
      </Grid>
      <Snackbar
        open={failureSnackbar}
        autoHideDuration={6000}
        onClose={() => handleFailureSnackClose()}
      >
        <Alert
          onClose={() => handleFailureSnackClose()}
          severity="error"
          sx={{ width: "100%" }}
        >
          Customer could not be created check if their email is already in use.
        </Alert>
      </Snackbar>
      <Snackbar
        open={successSnackbar}
        autoHideDuration={6000}
        onClose={() => handleSuccessSnackClose()}
      >
        <Alert
          onClose={() => handleSuccessSnackClose()}
          severity="success"
          sx={{ width: "100%" }}
        >
          Customer created succesfully!
        </Alert>
      </Snackbar>
    </Grid>
  );
}
