import { Alert, Grid, Paper, Snackbar, Typography } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { Await, useLoaderData } from "react-router-dom";
import { modifyShopifyCustomer } from "./api/ShopifyCustomer";
import { useState } from "react";

export default function CustomerTable() {
  const customerLoader = useLoaderData();

  // Filter Customers by Employer Metafield
  const validEmployerMetafieldValues = [
    "ccofs",
    "envision",
    "aofs",
    "flagship",
    "rockcliff",
    "uoms",
  ];
  let filteredCustomers = customerLoader.filter((customer) => {
    if (customer.metafields !== undefined) {
      return customer.metafields.find(
        (metafield) =>
          metafield.key === "employer" &&
          validEmployerMetafieldValues.includes(metafield.value)
      );
    } else {
      return false;
    }
  });

  const [customers, setCustomers] = useState(filteredCustomers);
  const [successSnackbar, setSuccessSnackbar] = useState(false);
  const [failureSnackbar, setFailureSnackbar] = useState(false);

  const columns = [
    { field: "firstName", headerName: "First Name", width: 150 },
    { field: "lastName", headerName: "Last Lame", width: 150 },
    { field: "email", headerName: "Email", width: 200 },
    {
      field: "employer",
      headerName: "Employer",
      width: 100,
      editable: true,
      type: "singleSelect",
      valueOptions: [
        "aofs",
        "ccofs",
        "envision",
        "flagship",
        "rockcliff",
        "uoms",
      ],
      valueGetter: (params) => {
        let matched_meta = params.row.metafields.find(
          (meta) => meta.key === "employer"
        );
        return matched_meta ? matched_meta.value : null;
      },
    },
    {
      field: "job_type",
      headerName: "Job Type",
      width: 100,
      editable: true,
      type: "singleSelect",
      valueOptions: ["admin", "clinical"],
      valueGetter: (params) => {
        let matched_meta = params.row.metafields.find(
          (meta) => meta.key === "job_type"
        );
        return matched_meta ? matched_meta.value : null;
      },
    },
    {
      field: "work_location",
      headerName: "Work Location",
      width: 150,
      editable: true,
      type: "singleSelect",
      valueOptions: (params) => {
        switch (
          params.row.metafields.find((meta) => meta.key === "employer").value
        ) {
          case "aofs":
            return ["Bermuda Run / Advance Office", "Kernersville "];
          case "ccofs":
            return [
              "AOMS : Lexington",
              "AOMS : West Columbia",
              "Billingsley",
              "Blakeney",
              "CCOFS of Anderson",
              "Cherrydale",
              "Columbia",
              "Concord",
              "Denver",
              "Eastside",
              "Gastonia",
              "Greenwood",
              "Matthews",
              "Mooresville",
              "Rock Hill",
              "Simpsonville",
              "Souther Pines",
              "Spartanburg : East",
              "Spartanburg : West",
              "University",
              "Verdae",
            ];
          case "envision":
            return ["Envision - Blakeney", "Envision - Issaqueena"];
          case "flagship":
            return ["Corporate"];
          case "rockcliff":
            return [
              "Asheville",
              "Hendersonville",
              "Sylva",
              "Vanderbilt",
              "Waynesville",
            ];
          case "uoms":
            return ["OMS Upstate Easley", "OMS Upstate Seneca"];
          default:
            return ["Valid Employer Not Found"];
        }
      },
      valueGetter: (params) => {
        let matched_meta = params.row.metafields.find(
          (meta) => meta.key === "work_location"
        );
        return matched_meta ? matched_meta.value : null;
      },
    },
    {
      field: "hire_date",
      headerName: "Hire Date",
      width: 150,
      editable: true,
      type: "date",
      valueGetter: (params) => {
        let matched_meta = params.row.metafields.find(
          (meta) => meta.key === "hire_date"
        );
        return matched_meta ? matched_meta.value : null;
      },
    },
  ];

  async function handleRowChange(params) {
    let { row } = params;

    let updatedCustomerValues = row;
    let customerIndex = customers.findIndex(
      (customer) => customer.id === updatedCustomerValues.id
    );
    let currentCustomerValues = customers[customerIndex];

    var customerPayload = {
      id: currentCustomerValues.id,
      metafields: [],
    };

    // Have To Use This Logic Due To Mix Of Using A Metafield Class on customers vs Column Names on Rows
    if (
      !currentCustomerValues.metafields.find((meta) => meta.key === "hire_date")
    ) {
      // Original Hire Date Value Was Null AND Has New Hire Date Value
      if (updatedCustomerValues["hire_date"] !== null) {
        // Set Hire Date Metafield
        customerPayload.metafields.push({
          ownerId: "gid://shopify/Customer/" + currentCustomerValues.id,
          key: "hire_date",
          value: updatedCustomerValues["hire_date"],
          type: "date",
          namespace: "customer_fields",
        });
      }
    } else {
      // Original Hire Date Value Was Not Null AND Has New Hire Date Value -> Updated Hire Date
      if (
        updatedCustomerValues["hire_date"] !==
        currentCustomerValues.metafields.find(
          (meta) => meta.key === "hire_date"
        ).value
      ) {
        customerPayload.metafields.push({
          ownerId: "gid://shopify/Customer/" + currentCustomerValues.id,
          key: "hire_date",
          value: updatedCustomerValues["hire_date"],
          type: "date",
          namespace: "customer_fields",
        });
      }
    }

    // Handle Job Type Metafield Changes
    if (
      !currentCustomerValues.metafields.find((meta) => meta.key === "job_type")
    ) {
      // Original Job Type Value Was Null AND Has New Job Type Value
      if (updatedCustomerValues["job_type"] !== null) {
        // Set Job Type Metafield
        customerPayload.metafields.push({
          ownerId: "gid://shopify/Customer/" + currentCustomerValues.id,
          key: "job_type",
          value: updatedCustomerValues["job_type"],
          type: "single_line_text_field",
          namespace: "customer_fields",
        });
      }
    } else {
      // Original Job Type Value Was Not Null AND Has New Job Type Value
      if (
        updatedCustomerValues["job_type"] !==
        currentCustomerValues.metafields.find((meta) => meta.key === "job_type")
          .value
      ) {
        customerPayload.metafields.push({
          ownerId: "gid://shopify/Customer/" + currentCustomerValues.id,
          key: "job_type",
          value: updatedCustomerValues["job_type"],
          type: "single_line_text_field",
          namespace: "customer_fields",
        });
      }
    }

    // Handle Work Location Metafield Changes
    if (
      !currentCustomerValues.metafields.find(
        (meta) => meta.key === "work_location"
      )
    ) {
      // Original Job Type Value Was Null AND Has New Job Type Value
      if (updatedCustomerValues["work_location"] !== null) {
        // Set Job Type Metafield
        customerPayload.metafields.push({
          ownerId: "gid://shopify/Customer/" + currentCustomerValues.id,
          key: "work_location",
          value: updatedCustomerValues["work_location"],
          type: "single_line_text_field",
          namespace: "customer_fields",
        });
      }
    } else {
      // Original Job Type Value Was Not Null AND Has New Job Type Value
      if (
        updatedCustomerValues["work_location"] !==
        currentCustomerValues.metafields.find(
          (meta) => meta.key === "work_location"
        ).value
      ) {
        customerPayload.metafields.push({
          ownerId: "gid://shopify/Customer/" + currentCustomerValues.id,
          key: "work_location",
          value: updatedCustomerValues["work_location"],
          type: "single_line_text_field",
          namespace: "customer_fields",
        });
      }
    }

    // Handle Work Location Metafield Changes
    if (
      !currentCustomerValues.metafields.find((meta) => meta.key === "employer")
    ) {
      // Original Job Type Value Was Null AND Has New Job Type Value
      if (updatedCustomerValues["employer"] !== null) {
        // Set Job Type Metafield
        customerPayload.metafields.push({
          ownerId: "gid://shopify/Customer/" + currentCustomerValues.id,
          key: "employer",
          value: updatedCustomerValues["employer"],
          type: "single_line_text_field",
          namespace: "customer_fields",
        });
      }
    } else {
      // Original Job Type Value Was Not Null AND Has New Job Type Value
      if (
        updatedCustomerValues["employer"] !==
        currentCustomerValues.metafields.find((meta) => meta.key === "employer")
          .value
      ) {
        customerPayload.metafields.push({
          ownerId: "gid://shopify/Customer/" + currentCustomerValues.id,
          key: "employer",
          value: updatedCustomerValues["employer"],
          type: "single_line_text_field",
          namespace: "customer_fields",
        });
      }
    }

    let updatedCustomer = await modifyShopifyCustomer(customerPayload);

    // Update GUI Based on API Call Results
    if (updatedCustomer != null) {
      let customersCopy = [...customers];
      customersCopy[customerIndex] = updatedCustomer;
      setCustomers(customersCopy);
      setSuccessSnackbar(true);
    } else {
      setFailureSnackbar(true);
    }
  }

  function handleSuccessSnackClose(event, reason) {
    if (reason === "clickaway") {
      return;
    }

    setSuccessSnackbar(false);
  }

  function handleFailureSnackClose(event, reason) {
    if (reason === "clickaway") {
      return;
    }

    setFailureSnackbar(false);
  }

  return (
    <Await
      resolve={customers}
      // TODO - Add errorElement Here
    >
      <Grid container spacing={3}>
        <Grid item xs={12} md={12} lg={12}>
          <Paper
            sx={{
              p: 2,
              display: "flex",
              flexDirection: "column",
              height: "auto",
            }}
          >
            <Typography variant="h4">Modify Customers</Typography>
            <br></br>
            <Typography variant="h6">How To Use </Typography>
            Customers are edited one row at a time. To edit a customer, just
            double click on the cell you want to edit. Then use the provided
            dropdown menus and date selectors in the table to update values. To
            submit your edits click off the current row.
            <br></br>
            <br></br>
            Currently only the following columns can be edited: employer, job
            type, work location, and hire date.
            <br></br>
            <br></br>
            <div style={{ height: 700, width: "100%" }}>
              <DataGrid
                rows={customers}
                columns={columns}
                pageSize={10}
                rowsPerPageOptions={[10]}
                editMode="row"
                onRowEditStop={handleRowChange}
              />
            </div>
            <br></br>
            <Typography variant="h6">Notes</Typography>
            <br></br>
            If you hover over the column names there are tools to sort and
            filter the data in the table.
            <br></br>
            <br></br>
            Currently, the the drop down shown when editing Work Location does
            not update if you are trying to edit the employer at the same time.
            Update the employer cell first then click off the current row to
            update the employer first. Then double click on the work location
            cell, it will now have the correct drop down for the selected
            employer.
            <br></br>
            <br></br>
            The Hire Date date column data must be entered in the following
            temporarily appear in DD/MM/YYY format, but should be converted to
            YYYY-MM-DD after hitting enter.
            <br></br>
          </Paper>
        </Grid>
        <Snackbar
          open={successSnackbar}
          autoHideDuration={6000}
          onClose={() => handleSuccessSnackClose()}
        >
          <Alert
            onClose={() => handleSuccessSnackClose()}
            severity="success"
            sx={{ width: "100%" }}
          >
            Customer fields updated successfully!
          </Alert>
        </Snackbar>
        <Snackbar
          open={failureSnackbar}
          autoHideDuration={6000}
          onClose={() => handleFailureSnackClose()}
        >
          <Alert
            onClose={() => handleFailureSnackClose()}
            severity="error"
            sx={{ width: "100%" }}
          >
            Error updating customer, please try again or conntact Knighten
            Uniforms for support.
          </Alert>
        </Snackbar>
      </Grid>
    </Await>
  );
}
