import {
  Alert,
  Button,
  Grid,
  Paper,
  Snackbar,
  Typography,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import Papa from "papaparse";
import React, { useState } from "react";
import { createShopifyCustomer } from "./api/ShopifyCustomer";

import "./CreateCustomerMultiple.css";

export default function CreateCustomerMultiple() {
  const [newCustomerList, setNewCustomerList] = useState();
  const [failureSnackbar, setFailureSnackbar] = useState(false);
  const [successSnackbar, setSuccessSnackbar] = useState(false);

  function handleCSVUpload(event) {
    Papa.parse(event.target.files[0], {
      header: true,
      skipEmptyLines: true,
      error: function (err, file, inputElem, reason) {
        console.log("Error occured parsing CSV file.");
      },
      complete: function (results) {
        setNewCustomerList(
          results.data.map((customer, i) => ({
            ...customer,
            id: i,
            error: null,
          }))
        );
      },
    });
  }

  async function handleCreateBtn(event) {
    const customersCreated = await Promise.all(
      newCustomerList.map(async (customer) => {
        // Accounts for Time Zone Issues
        let hireDate = new Date(customer.hire_date);
        const offset = hireDate.getTimezoneOffset();
        hireDate = new Date(hireDate.getTime() - offset * 60 * 1000);

        const new_customer = {
          firstName: customer.first_name,
          lastName: customer.last_name,
          email: customer.email,
          employer: customer.brand.toLowerCase(),
          work_location: customer.work_location,
          job_type: customer.job_type.toLowerCase(),
          hire_date: hireDate.toISOString().split("T")[0],
          tags: generateCustomerTags(customer.brand, customer.job_type),
        };

        return await createShopifyCustomer(new_customer);
      })
    );

    if (customersCreated.every((customer) => customer != null)) {
      setSuccessSnackbar(true);
      setNewCustomerList(
        newCustomerList.map((customer) => ({ ...customer, error: false }))
      );
    } else {
      setFailureSnackbar(true);
      setNewCustomerList(
        customersCreated.map((customer, i) => ({
          ...newCustomerList[i],
          error: customer === undefined,
        }))
      );
    }
  }

  let generateCustomerTags = (brand, jobType) => {
    return brand.toLowerCase() === "flagship"
      ? "flagship-admin"
      : brand.toLowerCase() + "-" + jobType.toLowerCase();
  };

  function handleFailureSnackClose(event, reason) {
    if (reason === "clickaway") {
      return;
    }

    setFailureSnackbar(false);
  }

  function handleSuccessSnackClose(event, reason) {
    if (reason === "clickaway") {
      return;
    }

    setSuccessSnackbar(false);
  }

  const columns = [
    { field: "first_name", headerName: "First Name", width: 100 },
    { field: "last_name", headerName: "Last Lame", width: 100 },
    { field: "email", headerName: "Email", width: 250 },
    { field: "brand", headerName: "Brand", width: 130 },
    { field: "work_location", headerName: "Work Location", width: 200 },
    { field: "job_type", headerName: "Job Type", width: 100 },
    { field: "hire_date", headerName: "Hire Date", width: 200 },
  ];

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} md={12} lg={12}>
        <Paper
          sx={{
            p: 2,
            display: "flex",
            flexDirection: "column",
            height: "auto",
          }}
        >
          <Typography variant="h4">Create Multiple Customers</Typography>
          <br></br>
          <Button variant="contained" component="label">
            Upload Customer CSV File
            <input
              hidden
              id="csv-upload"
              accept=".csv"
              multiple
              type="file"
              onChange={handleCSVUpload}
            />
          </Button>
        </Paper>
      </Grid>
      {newCustomerList && (
        <React.Fragment>
          <Grid item xs={12} md={12} lg={12}>
            <Paper
              sx={{
                p: 2,
                display: "flex",
                flexDirection: "column",
                height: "auto",
              }}
            >
              <Typography variant="h4">
                Customer Data Found In Spreadsheet
              </Typography>
              <br></br>
              <div style={{ height: 700, width: "100%" }}>
                <DataGrid
                  rows={newCustomerList}
                  columns={columns}
                  pageSize={100}
                  rowsPerPageOptions={[100]}
                  getRowClassName={(params) => {
                    if (params.row.error == null) return "null";
                    else if (params.row.error) return "error";
                    else return "no-error";
                  }}
                />
              </div>
              <br></br>
              <Button
                variant="contained"
                component="label"
                onClick={handleCreateBtn}
              >
                Create Customers
              </Button>
            </Paper>
          </Grid>
        </React.Fragment>
      )}
      <Snackbar
        open={failureSnackbar}
        autoHideDuration={6000}
        onClose={() => handleFailureSnackClose()}
      >
        <Alert
          onClose={() => handleFailureSnackClose()}
          severity="error"
          sx={{ width: "100%" }}
        >
          Some customers were not able to be created, they are marked in red.
        </Alert>
      </Snackbar>
      <Snackbar
        open={successSnackbar}
        autoHideDuration={6000}
        onClose={() => handleSuccessSnackClose()}
      >
        <Alert
          onClose={() => handleSuccessSnackClose()}
          severity="success"
          sx={{ width: "100%" }}
        >
          All customer were created.
        </Alert>
      </Snackbar>
    </Grid>
  );
}
