import { Button, Grid, Paper, Typography } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { Await, useLoaderData } from "react-router";
import { Link } from "react-router-dom";

export default function DiscountCodeDetails() {
  const [allCustomers, priceRule] = useLoaderData();

  // Filter Customers by Employer Metafield
  const validEmployerMetafieldValues = [
    "ccofs",
    "envision",
    "aofs",
    "flagship",
    "rockcliff",
    "uoms",
  ];

  let filteredCustomers = allCustomers.filter((customer) => {
    if (customer.metafields !== undefined) {
      return customer.metafields.find(
        (metafield) =>
          metafield.key === "employer" &&
          validEmployerMetafieldValues.includes(metafield.value)
      );
    } else {
      return false;
    }
  });

  let discountCustomers = filteredCustomers.filter((customer) => {
    if (Object.hasOwn(priceRule, "customers")) {
      return priceRule.customers.includes(
        "gid://shopify/CustomerSegmentMember/" + customer.id
      );
    }

    return priceRule.customerSelection.customers.includes(customer.id);
  });

  const discountCustomerColumns = [
    { field: "firstName", headerName: "First Name", width: 100 },
    { field: "lastName", headerName: "Last Name", width: 100 },
    { field: "email", headerName: "Email", width: 250 },
    {
      field: "Employer",
      headerName: "Employer",
      width: 100,
      editable: false,
      valueGetter: (params) => {
        let matched_meta = params.row.metafields.find(
          (meta) => meta.key === "employer"
        );
        return matched_meta ? matched_meta.value : null;
      },
    },
    {
      field: "Job Type",
      headerName: "Job Type",
      width: 100,
      editable: false,
      valueGetter: (params) => {
        let matched_meta = params.row.metafields.find(
          (meta) => meta.key === "job_type"
        );
        return matched_meta ? matched_meta.value : null;
      },
    },
    {
      field: "Work Location",
      headerName: "Work Location",
      width: 150,
      editable: false,
      valueGetter: (params) => {
        let matched_meta = params.row.metafields.find(
          (meta) => meta.key === "work_location"
        );
        return matched_meta ? matched_meta.value : null;
      },
    },
    {
      field: "Hire Date",
      headerName: "Hire Date",
      width: 100,
      editable: false,
      valueGetter: (params) => {
        let matched_meta = params.row.metafields.find(
          (meta) => meta.key === "hire_date"
        );
        return matched_meta ? matched_meta.value : null;
      },
    },
    // TODO - Add back onces order graphql is setup
    // {
    //   field: "Used Code",
    //   headerName: "Used Code",
    //   width: 100,
    //   editable: false,
    //   valueGetter: (params) => {
    //     let customerID = params.row.id;
    //     let ordersUsingDiscount = allOrders.filter(
    //       (order) =>
    //         order.customer.id === customerID &&
    //         order.discount_codes.find((code) => code.code === priceRule.title)
    //     );
    //     return ordersUsingDiscount.length > 0 ? "Yes" : "No";
    //   },
    // },
  ];

  return (
    <Await
      resolve={priceRule}
      // TODO - Add errorElement Here
    >
      <Grid container spacing={3}>
        <Grid item xs={12} md={12} lg={12}>
          <Paper
            sx={{
              p: 2,
              display: "flex",
              flexDirection: "column",
              height: "auto",
            }}
          >
            <Typography variant="h4">
              Discount Code: {priceRule.title}
            </Typography>
            <br></br>
            <Typography variant="h6">Assigned Customers</Typography>
            <div style={{ height: 900, width: "100%" }}>
              <DataGrid
                rows={discountCustomers}
                columns={discountCustomerColumns}
                pageSize={15}
                rowsPerPageOptions={[15]}
                //checkboxSelection
              />
            </div>
            <Button
              component={Link}
              to={"/discount-codes/" + priceRule.id + "/add"}
              variant="contained"
            >
              Add Customer To Discount Code
            </Button>
          </Paper>
        </Grid>
      </Grid>
    </Await>
  );
}
