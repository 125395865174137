import { apiRequest } from "../authConfig";
import { msalInstance } from "../index";

export async function getBearerToken() {
  const account = msalInstance.getActiveAccount();
  if (!account) {
    throw Error(
      "No active account! Verify a user has been signed in and setActiveAccount has been called."
    );
  }

  const response = await msalInstance.acquireTokenSilent({
    ...apiRequest,
    account: account,
  });

  return response.accessToken;
}

export async function getAllShopifyOrders() {
  const token = await getBearerToken();

  const headers = new Headers();
  const bearer = `Bearer ${token}`;

  headers.append("Authorization", bearer);

  const options = {
    method: "GET",
    headers: headers,
  };

  return fetch(
    `https://ku-ordering-api.azurewebsites.net/api/shopify_order?code=tPaHxDZI4zDWu7GeaYEKbOsPJMrLN-n1nhqDiJmq510mAzFua0cUMQ==`,
    options
  )
    .then((response) => response.json())
    .catch((error) => console.log(error));
}

export async function getAllShopifyOrdersForCustomer(customer_id) {
  const token = await getBearerToken();

  const headers = new Headers();
  const bearer = `Bearer ${token}`;

  headers.append("Authorization", bearer);

  const options = {
    method: "GET",
    headers: headers,
  };

  return fetch(
    `https://ku-ordering-api.azurewebsites.net/api/shopify_order?customer_id=${customer_id}&code=tPaHxDZI4zDWu7GeaYEKbOsPJMrLN-n1nhqDiJmq510mAzFua0cUMQ==`,
    options
  )
    .then((response) => response.json())
    .catch((error) => console.log(error));
}

export async function getShopifyOrderById(order_id) {
  const token = await getBearerToken();

  const headers = new Headers();
  const bearer = `Bearer ${token}`;

  headers.append("Authorization", bearer);

  const options = {
    method: "GET",
    headers: headers,
  };

  return fetch(
    `https://ku-ordering-api.azurewebsites.net/api/shopify_order/${order_id}?code=tPaHxDZI4zDWu7GeaYEKbOsPJMrLN-n1nhqDiJmq510mAzFua0cUMQ==`,
    options
  )
    .then((response) => response.json())
    .catch((error) => console.log(error));
}
