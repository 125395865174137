import {
  Alert,
  Box,
  Button,
  Grid,
  Paper,
  Snackbar,
  TextField,
  Typography,
} from "@mui/material";
import { useState } from "react";
import { createShopifyPriceRule } from "./api/ShopifyPriceRules";
import { useNavigate } from "react-router-dom";

export default function CreateDiscountCode() {
  const [title, setTitle] = useState("");
  const [titleError, setTitleError] = useState(false);
  const [value, setValue] = useState("");
  const [valueError, setValueError] = useState(false);
  const [failureSnackbar, setFailureSnackbar] = useState(false);

  const navigate = useNavigate();

  const handleValueChange = (event) => {
    const inputValue = event.target.value;
    if (inputValue < 0) {
      setValueError(true);
    } else {
      setValueError(false);
      setValue(inputValue);
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!title) {
      setTitleError(true);
    } else {
      setTitleError(false);

      var discountCodePayload = {
        priceRule: {
          title: "FLAGSHIP-" + title.toUpperCase().split(" ").join("-"),
          validityPeriod: {
            start: new Date().toISOString().split("T")[0],
          },
          value: {
            fixedAmountValue: "-" + value,
          },
          target: "LINE_ITEM",
          allocationMethod: "ACROSS",
          customerSelection: {
            forAllCustomers: false,
          },
          itemEntitlements: {
            targetAllLineItems: true,
          },
          oncePerCustomer: true,
        },

        priceRuleDiscountCode: {
          code: "FLAGSHIP-" + title.toUpperCase().split(" ").join("-"),
        },
      };

      const newDiscountCode = await createShopifyPriceRule(discountCodePayload);

      // Catch Error Creating Discount Code
      if (!newDiscountCode) {
        setFailureSnackbar(true);
      } else {
        navigate("/discount-codes");
      }
    }
  };

  function handleFailureSnackClose(event, reason) {
    if (reason === "clickaway") {
      return;
    }

    setFailureSnackbar(false);
  }

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} md={12} lg={12}>
        <Paper
          sx={{
            p: 2,
            display: "flex",
            flexDirection: "column",
            height: "auto",
          }}
        >
          <Typography variant="h4">Create Discount Code</Typography>
          <br></br>
          <br></br>
          <Typography variant="h8">
            All discount codes can only be used once per customer and will be
            enabled at time of creation
          </Typography>

          <Box
            component="form"
            onSubmit={handleSubmit}
            noValidate
            sx={{ mt: 1 }}
          >
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="title"
              label="Discount Code Title"
              name="title"
              autoFocus
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              error={titleError}
              helperText={titleError ? "Discount Code Title is required" : ""}
            />
            <Typography variant="h8" gutterBottom>
              Note: The final discount code will be FLAGSHIP-
              {title.toUpperCase().split(" ").join("-")}
            </Typography>
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              name="value"
              label="Amount Off Order ($)"
              type="number"
              id="value"
              value={value}
              onChange={handleValueChange}
              error={valueError}
              helperText={
                valueError ? "Discount Value must be a positive number" : ""
              }
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              sx={{ mt: 3, mb: 2 }}
            >
              Create Discount Code
            </Button>
          </Box>
        </Paper>
      </Grid>
      <Snackbar
        open={failureSnackbar}
        autoHideDuration={6000}
        onClose={() => handleFailureSnackClose()}
      >
        <Alert
          onClose={() => handleFailureSnackClose()}
          severity="error"
          sx={{ width: "100%" }}
        >
          Error unable to create discount code, please try again or contact
          Knighten Uniforms for support.
        </Alert>
      </Snackbar>
    </Grid>
  );
}
