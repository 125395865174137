import { Button, Grid, Paper, Typography } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { Await, useLoaderData } from "react-router";
import { Link } from "react-router-dom";

export default function ManageDiscountCodes() {
  const priceRuleLoader = useLoaderData();

  const validDiscountCodeBaseNames = ["FLAGSHIP"];
  let filteredDiscountCodes = priceRuleLoader.filter((priceRule) => {
    return (
      validDiscountCodeBaseNames.filter((code) =>
        priceRule.title.includes(code)
      ).length > 0
    );
  });

  const discountColumns = [
    { field: "title", headerName: "Discount Code", width: 300 },
    {
      field: "value",
      headerName: "Discount Amount",
      width: 150,
      valueGetter: (params) => {
        return `$${params.row.valueV2.amount.slice(1)}0`;
      },
    },
    {
      field: "numb_cust_assigned",
      headerName: "Customers Assigned",
      width: 200,
      valueGetter: (params) => {
        if (Object.hasOwn(params.row, "customers")) {
          return `${params.row.customers.length}`;
        }

        return `${params.row.customerSelection.customers.length}`;
      },
    },
    {
      field: "internal_link",
      headerName: "Link",
      width: 100,
      renderCell: (params) => (
        <Link to={"/discount-codes/" + params.row.id}>Details</Link>
      ),
    },
  ];

  return (
    <Await
      resolve={priceRuleLoader}
      // TODO - Add errorElement Here
    >
      <Grid container spacing={3}>
        <Grid item xs={12} md={12} lg={12}>
          <Paper
            sx={{
              p: 2,
              display: "flex",
              flexDirection: "column",
              height: "auto",
            }}
          >
            <Typography variant="h4">Manage Discount Codes</Typography>
            <br></br>
            <Typography variant="h6">Available Discount Codes</Typography>
            <div style={{ height: 700, width: "100%" }}>
              <DataGrid
                rows={filteredDiscountCodes}
                columns={discountColumns}
                pageSize={10}
                rowsPerPageOptions={[10]}
                //checkboxSelection
              />
            </div>
            <br></br>
            <Grid container columns={2}>
              <Grid item>
                <Button
                  variant="contained"
                  component={Link}
                  to="/create-discount-code"
                >
                  Create New Discount Code
                </Button>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    </Await>
  );
}
