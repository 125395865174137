import { Button, Grid, Paper, Typography } from "@mui/material";
import { Link } from "react-router-dom";

export default function CreateCustomer() {
  return (
    <Grid container spacing={3}>
      <Grid item xs={12} md={12} lg={12}>
        <Paper
          sx={{
            p: 2,
            display: "flex",
            flexDirection: "column",
            height: "auto",
          }}
        >
          <Typography variant="h4">Create Single Customers</Typography>
          <br></br>
          <br></br>
          <Grid container columns={2}>
            <Grid item>
              <Button
                variant="contained"
                component={Link}
                to="/create-customers/single"
              >
                Add Single User
              </Button>
            </Grid>
            <Grid item>
              <Button
                disabled
                variant="contained"
                component={Link}
                to="/create-customers/multiple"
                sx={{ ml: "10px" }}
              >
                Create Multiple Customers (Upload Spreadsheet)
              </Button>
            </Grid>
          </Grid>
        </Paper>
      </Grid>
    </Grid>
  );
}
